import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { accountReducer } from './slices/AccountSlice';
import { transactionReducer } from './slices/TransactionSlice';
import { appSlice } from './slices/AppSlice';

const store = configureStore({
  reducer: {
    app: appSlice.reducer,
    account: accountReducer,
    transaction: transactionReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export default store;

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
