import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { ChainId } from '../constansts';

export enum TransactionType {
  Approve,
}

export interface BaseTransactionInfo {
  type: TransactionType;
}

export interface ApproveTransactionInfo extends BaseTransactionInfo {
  type: TransactionType.Approve;
  tokenAddress: string;
  spender: string;
}

export interface SerializableTransactionReceipt {
  to: string;
  from: string;
  contractAddress: string;
  transactionIndex: number;
  blockHash: string;
  transactionHash: string;
  blockNumber: number;
  status?: number;
}

export type TransactionSliceState = {
  [chainId in ChainId]?: Record<string, TransactionDetails>;
};

export interface TransactionDetails {
  hash: string;
  receipt?: SerializableTransactionReceipt;
  lastCheckedBlockNumber?: number;
  addedTime: number;
  confirmedTime?: number;
  from: string;
  info: TransactionInfo;
}

export type TransactionInfo = ApproveTransactionInfo;

const now = (): number => new Date().getTime();

export const transactionSlice = createSlice<TransactionSliceState, SliceCaseReducers<TransactionSliceState>>({
  name: 'transaction',
  initialState: {},
  reducers: {
    addTransaction(
      state,
      {
        payload: { chainId, hash, info, from },
      }: PayloadAction<{ chainId: ChainId; from: string; hash: string; info: ApproveTransactionInfo }>,
    ) {
      if (state[chainId]?.[hash]) {
        throw Error('Attempted to add existing transaction.');
      }

      const txs = state[chainId] ?? {};
      txs[hash] = { hash, info, from, addedTime: now() };

      return {
        ...state,
        [chainId]: txs,
      };
    },
    clearAllTransactions(state, { payload: { chainId } }: PayloadAction<{ chainId: ChainId }>) {
      if (!state[chainId]) {
        return state;
      }
      return {
        ...state,
        [chainId]: {},
      };
    },
    checkedTransaction(
      state,
      {
        payload: { chainId, hash, blockNumber },
      }: PayloadAction<{ chainId: ChainId; hash: string; blockNumber: number }>,
    ) {
      const tx = state[chainId]?.[hash];
      if (!tx) {
        return state;
      }
      if (!tx.lastCheckedBlockNumber) {
        tx.lastCheckedBlockNumber = blockNumber;
      } else {
        tx.lastCheckedBlockNumber = Math.max(blockNumber, tx.lastCheckedBlockNumber);
      }
      return {
        ...state,
        [chainId]: {
          ...state[chainId],
          [hash]: tx,
        },
      };
    },
  },
});

export const transactionReducer = transactionSlice.reducer;
