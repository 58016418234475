import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {}

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#3D80F6',
      contrastText: '#fff',
    },
    background: {
      default: '#fff',
    },
    text: {
      primary: '#1B1E28',
      secondary: '#575D64',
    },
    divider: '#EFF0F2',
  },
  typography: {
    body1: {
      fontSize: '0.875rem',
    },
    subtitle1: {
      fontSize: '1rem',
      lineHeight: 1.5,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        img: {
          verticalAlign: 'middle',
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          padding: 24,
        },
        rounded: {
          borderRadius: 12,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          textTransform: 'unset',
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          padding: 0,
          minHeight: 46,
          minWidth: 'unset',
          marginRight: 40,
          textTransform: 'unset',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '14px 16px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 14,
          marginTop: 8,
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
  },
});

export default defaultTheme;
