export class Currency {
  static readonly ETHER: Currency = new Currency(18, 'BNB', 'BNB');

  static readonly BNB: Currency = new Currency(18, 'BNB', 'BNB');

  protected constructor(
    public readonly decimals: number,
    public readonly symbol: string,
    public readonly name: string,
  ) {}
}
