import { createAsyncThunk, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { providers } from 'ethers';
import { TokenAmount } from '../utils/currency/token-amount';
import { Token } from '../utils/currency/token';
import {
  getBaseRewardPoolContract,
  getErc20Contract,
  getMasterChefContract,
  getVirtualBalanceRewardPoolContract,
} from '../utils/getContract';
import { ChainId } from '../constansts';
import { getConfig } from '../utils/getConfig';

interface Connection {
  provider?: providers.JsonRpcProvider;
  account?: string;
  chainId?: ChainId;
}

export interface AccountSliceState {
  balances: Record<string, TokenAmount | undefined>;
  stakingAmount?: TokenAmount;
  baseReward?: TokenAmount;
  extraReward?: TokenAmount;
  masterChefPendingReward?: TokenAmount;
}

export const getBalance = createAsyncThunk(
  'account/getBalance',
  async ({
    account,
    provider,
    token,
  }: {
    provider?: providers.JsonRpcProvider;
    account?: string;
    token?: Token;
  }): Promise<TokenAmount | undefined> => {
    if (!token || !account || !provider) {
      return Promise.resolve(undefined);
    }
    const contract = getErc20Contract(token.address, provider, account);
    const balance = await contract.balanceOf(account);
    return new TokenAmount(token, balance);
  },
);

export const getStakingAmount = createAsyncThunk(
  'account/getStakingAmount',
  async ({ chainId, account, provider }: Connection): Promise<TokenAmount | undefined> => {
    const { triCrvLp, baseRewardPoolAddress } = getConfig(chainId);

    if (!triCrvLp || !baseRewardPoolAddress || !account || !provider) {
      return undefined;
    }

    return new TokenAmount(
      triCrvLp,
      await getBaseRewardPoolContract(baseRewardPoolAddress, provider, account).balanceOf(account),
    );
  },
);

export const getBaseReward = createAsyncThunk(
  'account/getBaseReward',
  async ({ chainId, account, provider }: Connection): Promise<TokenAmount | undefined> => {
    const { cCrv, baseRewardPoolAddress } = getConfig(chainId);

    if (!cCrv || !baseRewardPoolAddress || !account || !provider) {
      return Promise.resolve(undefined);
    }

    return new TokenAmount(
      cCrv,
      await getBaseRewardPoolContract(baseRewardPoolAddress, provider, account).earned(account),
    );
  },
);

export const getExtraReward = createAsyncThunk(
  'account/getExtraReward',
  async ({ account, chainId, provider }: Connection): Promise<TokenAmount | undefined> => {
    const { virtualBalanceRewardPoolAddress, cvx } = getConfig(chainId);

    if (!cvx || !virtualBalanceRewardPoolAddress || !account || !provider) {
      return Promise.resolve(undefined);
    }

    return new TokenAmount(
      cvx,
      await getVirtualBalanceRewardPoolContract(virtualBalanceRewardPoolAddress, provider, account).earned(account),
    );
  },
);

export const getMasterChefPendingReward = createAsyncThunk(
  'account/getMasterChefPendingReward',
  async ({ chainId, account, provider }: Connection): Promise<TokenAmount | undefined> => {
    const { masterChefAddress, cCrv } = getConfig(chainId);

    if (!masterChefAddress || !provider || !account || !cCrv) {
      return undefined;
    }

    return new TokenAmount(
      cCrv,
      await getMasterChefContract(masterChefAddress, provider, account).pendingcCRV(0, account),
    );
  },
);

export const getAllReward = createAsyncThunk('account/getAllReward', async (params: Connection, { dispatch }) =>
  Promise.all([
    dispatch(getBaseReward(params)),
    dispatch(getExtraReward(params)),
    dispatch(getMasterChefPendingReward(params)),
  ]),
);

export const accountSlice = createSlice<AccountSliceState, SliceCaseReducers<AccountSliceState>>({
  name: 'account',
  initialState: {
    balances: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBalance.fulfilled, (state, { payload }) => {
        if (payload === undefined) {
          return state;
        }
        return {
          ...state,
          balances: {
            ...state.balances,
            [payload.token.symbol]: payload,
          },
        };
      })
      .addCase(getStakingAmount.fulfilled, (state, { payload }) => ({
        ...state,
        stakingAmount: payload,
      }))
      .addCase(getBaseReward.fulfilled, (state, { payload }) => ({
        ...state,
        baseReward: payload,
      }))
      .addCase(getExtraReward.fulfilled, (state, { payload }) => ({
        ...state,
        extraReward: payload,
      }))
      .addCase(getMasterChefPendingReward.fulfilled, (state, { payload }) => ({
        ...state,
        masterChefPendingReward: payload,
      }));
  },
});

export const accountReducer = accountSlice.reducer;
