import { BigNumberish } from '@ethersproject/bignumber';

import { CurrencyAmount } from './currency-amount';
import { Token } from './token';

export class TokenAmount extends CurrencyAmount {
  constructor(public readonly token: Token, amount: BigNumberish) {
    super(token, amount);
  }
}
