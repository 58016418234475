import Big from 'big.js';
import { BigNumber } from 'ethers';
import { BigNumberish } from '@ethersproject/bignumber';

export class Fraction {
  readonly numerator: BigNumber;

  readonly denominator: BigNumber;

  constructor(numerator: BigNumberish, denominator: BigNumberish = 1) {
    this.numerator = BigNumber.from(numerator);
    this.denominator = BigNumber.from(denominator);
  }

  get quotient(): BigNumber {
    return this.numerator.div(this.denominator);
  }

  invert(): Fraction {
    return new Fraction(this.denominator, this.numerator);
  }

  add(other: Fraction | BigNumberish): Fraction {
    const otherParsed = other instanceof Fraction ? other : new Fraction(BigNumber.from(other));

    if (this.denominator.eq(otherParsed.denominator)) {
      return new Fraction(this.numerator.add(otherParsed.numerator), this.denominator);
    }

    return new Fraction(
      this.numerator.mul(otherParsed.denominator).add(otherParsed.numerator.mul(this.denominator)),
      this.denominator.mul(otherParsed.denominator),
    );
  }

  sub(other: Fraction | BigNumberish): Fraction {
    const otherParsed = other instanceof Fraction ? other : new Fraction(BigNumber.from(other));

    if (this.denominator.eq(otherParsed.denominator)) {
      return new Fraction(this.numerator.sub(otherParsed.numerator), this.denominator);
    }

    return new Fraction(
      this.numerator.mul(otherParsed.denominator).sub(otherParsed.numerator.mul(this.denominator)),
      this.denominator.mul(otherParsed.denominator),
    );
  }

  lt(other: Fraction | BigNumberish): boolean {
    const otherParsed = other instanceof Fraction ? other : new Fraction(BigNumber.from(other));
    return this.numerator.mul(otherParsed.denominator).lt(otherParsed.numerator.mul(this.denominator));
  }

  lte(other: Fraction | BigNumber): boolean {
    const otherParsed = other instanceof Fraction ? other : new Fraction(BigNumber.from(other));
    return this.numerator.mul(otherParsed.denominator).lte(otherParsed.numerator.mul(this.denominator));
  }

  eq(other: Fraction | BigNumberish): boolean {
    const otherParsed = other instanceof Fraction ? other : new Fraction(BigNumber.from(other));
    return this.numerator.mul(otherParsed.denominator).eq(otherParsed.numerator.mul(this.denominator));
  }

  gt(other: Fraction | BigNumberish): boolean {
    const otherParsed = other instanceof Fraction ? other : new Fraction(BigNumber.from(other));
    return this.numerator.mul(otherParsed.denominator).gt(otherParsed.numerator.mul(this.denominator));
  }

  mul(other: Fraction | BigNumberish): Fraction {
    const otherParsed = other instanceof Fraction ? other : new Fraction(BigNumber.from(other));
    return new Fraction(this.numerator.mul(otherParsed.numerator), this.denominator.mul(otherParsed.denominator));
  }

  div(other: Fraction | BigNumberish): Fraction {
    const otherParsed = other instanceof Fraction ? other : new Fraction(BigNumber.from(other));
    return new Fraction(this.numerator.mul(otherParsed.denominator), this.denominator.mul(otherParsed.numerator));
  }

  toString(): string {
    return Big(this.numerator.toString()).div(this.denominator.toString()).toFixed();
  }
}
