import { Contract, ContractInterface, providers } from 'ethers';
import { BaseRewardPool, ERC20, MasterChef, VirtualBalanceRewardPool } from '../typechain';
import { BASE_REWARD_POOL_ABI, ERC20_ABI, MASTER_CHEF_ABI, VIRTUAL_BALANCE_POOL_ABI } from '../abis';

export function getContract<T extends Contract>(
  address: string,
  abi: ContractInterface,
  provider: providers.JsonRpcProvider,
  account: string | undefined,
): T {
  if (address === undefined || provider === undefined) {
    throw new Error('Please connect wallet');
  }
  const library = account !== undefined ? provider.getSigner(account).connectUnchecked() : provider;
  return new Contract(address, abi, library) as T;
}

export function getErc20Contract(address: string, provider: providers.JsonRpcProvider, account: string): ERC20 {
  return getContract<ERC20>(address, ERC20_ABI, provider, account);
}

export function getBaseRewardPoolContract(
  address: string,
  provider: providers.JsonRpcProvider,
  account: string,
): BaseRewardPool {
  return getContract<BaseRewardPool>(address, BASE_REWARD_POOL_ABI, provider, account);
}

export function getVirtualBalanceRewardPoolContract(
  address: string,
  provider: providers.JsonRpcProvider,
  account: string,
): VirtualBalanceRewardPool {
  return getContract<VirtualBalanceRewardPool>(address, VIRTUAL_BALANCE_POOL_ABI, provider, account);
}

export function getMasterChefContract(
  address: string,
  provider: providers.JsonRpcProvider,
  account: string,
): MasterChef {
  return getContract<MasterChef>(address, MASTER_CHEF_ABI, provider, account);
}
