import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

export interface AppSliceState {
  blockNumber?: number;
}

export const appSlice = createSlice<AppSliceState, SliceCaseReducers<AppSliceState>>({
  name: 'app',
  initialState: {},
  reducers: {
    setBlockNumber(state, { payload }: PayloadAction<number>) {
      return {
        ...state,
        blockNumber: payload,
      };
    },
  },
});
