import { BigNumber, BigNumberish } from '@ethersproject/bignumber';
import { Fraction } from './fraction';
import { Currency } from './currency';

export class CurrencyAmount extends Fraction {
  constructor(public currency: Currency, amount: BigNumberish) {
    super(amount, BigNumber.from(10).pow(currency.decimals));
  }

  get raw(): BigNumber {
    return this.numerator;
  }
}
