import { useCallback, useEffect, useState } from 'react';
import { ChainId } from '../constansts';
import { useAppDispatch } from '../store';
import { appSlice } from '../slices/AppSlice';
import { useWeb3Context } from './web3Context';
import useDebounce from './useDebounce';

export function useBlock(): number | undefined {
  const { chainId, provider } = useWeb3Context();
  const [state, setState] = useState<{ chainId?: ChainId; block?: number }>({ chainId });

  const onBlock = useCallback(
    (block: number) => {
      setState((value) => {
        if (value.chainId !== chainId) {
          return value;
        }

        if (typeof value.block !== 'number') {
          return { chainId, block };
        }

        return { chainId, block: Math.max(block, value.block) };
      });
    },
    [chainId],
  );

  useEffect(() => {
    if (provider && chainId) {
      setState({ chainId });

      provider.getBlockNumber().then(onBlock).catch();
      provider.on('block', onBlock);

      return () => {
        provider.removeListener('block', onBlock);
      };
    }
    return undefined;
  }, [chainId, provider, onBlock]);

  const debouncedBlock = useDebounce(state.block, 100);

  return state.block ? debouncedBlock : undefined;
}

export function BlockUpdater(): null {
  const block = useBlock();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(appSlice.actions.setBlockNumber(block));
  }, [block, dispatch]);

  return null;
}
