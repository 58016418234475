import { Token } from './utils/currency/token';

export enum ChainId {
  mainnet = 1,
  ropsten = 3,
  rinkeby = 4,
  goerli = 5,
  kovan = 42,
  bscMainnet = 56,
  bscTestnet = 97,
}

export interface Config {
  baseRewardPoolAddress: string;
  cCrv: Token;
  cvx: Token;
  masterChefAddress: string;
  triCrvLp: Token;
  virtualBalanceRewardPoolAddress: string;
}

export interface Environment {
  config: { readonly [chainId in ChainId]?: Config };
}

export const env: Environment = {
  config: {
    [ChainId.goerli]: {
      baseRewardPoolAddress: '0x541b68aAC8BC3877e21aC236E872611578ac8F31',
      cCrv: new Token('0x27FFF6CE0391f009d10CA9862cB428b7BA472363', 18, 'cCRV', 'Congruent CRV'),
      cvx: new Token('0x02236a2F70eb0C6ee515ce899DC76b498b694d67', 18, 'CVX', 'CVX'),
      masterChefAddress: '0x814d62839F81b553978787b521Ad3F20D578cC7D',
      triCrvLp: new Token('0xDD3895A24aa8688DF057492620956Ce8A4F007dD', 18, 'TriCrvLp', 'TriCrvLp'),
      virtualBalanceRewardPoolAddress: '0xcd67ea4be7c69183a8c4fd0eee734491aaa8cb96',
    },
  },
};
