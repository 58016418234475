import React, { lazy } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import reportWebVitals from './reportWebVitals';
import defaultTheme from './themes/defaultTheme';
import Web3ContextProvider from './hooks/web3Context';
import store from './store';
import 'react-toastify/dist/ReactToastify.min.css';
import { BlockUpdater } from './hooks/useBlock';

const Home = lazy(() => import('./pages/Home'));
// const App = lazy(() => import('./pages/App'));

ReactDOM.render(
  <React.StrictMode>
    <Web3ContextProvider>
      <Provider store={store}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <BlockUpdater />
        <BrowserRouter>
          <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <Routes>
              <Route
                path="/"
                element={
                  <React.Suspense fallback={null}>
                    <Home />
                  </React.Suspense>
                }
              />
              {/* <Route */}
              {/*   path="/app" */}
              {/*   element={ */}
              {/*     <React.Suspense fallback={null}> */}
              {/*       <App /> */}
              {/*     </React.Suspense> */}
              {/*   } */}
              {/* /> */}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </ThemeProvider>
        </BrowserRouter>
      </Provider>
    </Web3ContextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
